// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.sandbox.safehavenmfb.com/',
  // apiUrl: "https://ibs-api-feature-onboarding-streak-jhendcbria-ew.a.run.app/",
  bankCode: '999240',
  privateKey:
    'bdcUzrwmP2WLxvUU5gepAFeGyh9APwkGcGMJfj64SDmhQrW8WjESj2jG9VLLAxE5',
  complianceUrl: 'https://paygate-compliance-sandbox.herokuapp.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
